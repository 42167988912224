<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card :elevation="1">
          <v-card-text class="py-1">
            <v-row dense>
              <v-col cols="12" md="3">
                <v-combobox
                  label="Month"
                  dense
                  outlined
                  hide-details
                  :items="months"
                  :loading="month_loading"
                  v-model="selectedMonth"
                  @focus="getMonths"
                  item-text="name"
                  item-value="id"
                  class="mb-1 custom-form"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="2" v-if="!selectedMonth">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="menu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="date_from"
                      label="Date From"
                      v-on="on"
                      class="custom-form"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_from"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="2" v-if="!selectedMonth">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="menu2"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="date_to"
                      label="Date To"
                      v-on="on"
                      class="custom-form"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_to"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="2">
                <v-btn
                  elevation="1"
                  small
                  color="primary"
                  dark
                  style="margin-top: -2px"
                  @click="getRecords"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-toolbar dense color="white" :elevation="1" height="30px">
          <v-toolbar-title class="subtitle-2">Salary Record</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
      <v-col cols="12">
        <v-btn color="primary" class="white--text" small dense @click="print">
          <v-icon left small dense> mdi-printer </v-icon>
          Print
        </v-btn>
      </v-col>
      <v-col cols="12" md="12" class="py-1" id="reportContent">
        <v-simple-table dense class="record_table">
          <tr class="record_heading_tr">
            <th>SL.</th>
            <th>Entry Date</th>
            <th>Month</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Total Gross Salary</th>
            <th>Total PF</th>
            <th>Total Deduction</th>
            <th>Total Advance</th>
            <th>Total Benefit</th>
            <th>Total Loan Received</th>
            <th>Total OT</th>
            <th>Total Shift Allowance</th>
            <th>Total Employee Due</th>
            <th>Total Net Payable</th>
            <th>Approved By</th>
            <th>Remark</th>
            <th>Action</th>
          </tr>
          <tr v-for="(record, sl) in records" :key="sl">
            <td>{{ ++sl }}</td>
            <td>{{ record.entry_date }}</td>
            <td>{{ record.month_name }}</td>
            <td>{{ record.date_from }}</td>
            <td>{{ record.date_to }}</td>
            <td>{{ record.total_gross_salary }}</td>
            <td>{{ record.total_pf }}</td>
            <td>{{ record.total_deduction }}</td>
            <td>{{ record.total_advance }}</td>
            <td>{{ record.total_benefit }}</td>
            <td>{{ record.total_loan_received }}</td>
            <td>{{ record.total_ot_amount }}</td>
            <td>{{ record.total_shift_allowance }}</td>
            <td>{{ record.total_overtake_salary }}</td>
            <td>{{ record.total_net_payable }}</td>
            <td>{{ record.approved_by_name }}</td>
            <td>{{ record.remark }}</td>
            <td>
              <v-btn
                icon
                x-small
                color="error"
                :to="'/salary_sheet/' + record.id"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <th colspan="5" style="text-align: right">Total=</th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_gross_salary;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_pf;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_deduction;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_advance;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_benefit;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_loan_received;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_ot_amount;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_shift_allowance;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_overtake_salary;
                }, 0)
              }}
            </th>
            <th>
              {{
                records.reduce((prev, curr) => {
                  return prev + +curr.total_net_payable;
                }, 0)
              }}
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import utility from "../../mixins/utility.mixin";
export default {
  mixins: [utility],
  data() {
    return {
      date_from: this.toISOLocal().substr(0, 10),
      date_to: this.toISOLocal().substr(0, 10),
      months: [],
      selectedMonth: null,
      records: [],
      month_loading: false,
      menu: false,
      menu2: false,
    };
  },
  watch: {
    selectedMonth(month) {
      this.date_from = this.toISOLocal().substr(0, 10);
      this.date_to = this.toISOLocal().substr(0, 10);
      if (!month || month.id == undefined) {
        return;
      }
      this.date_from = null;
      this.date_to = null;
    },
  },
  created() {},

  methods: {
    async getMonths() {
      this.month_loading = true;
      this.selectedMonth = null;
      this.months = await this.$store.dispatch("month/getSalaryMonths", {
        apiParams: {},
        options: {
          returnData: true,
        },
      });
      this.month_loading = false;
    },
    async getRecords() {
      this.records = await this.$store.dispatch("salary/getSalaries", {
        apiParams: {
          month_id: this.selectedMonth?.id ?? null,
          date_from: this.date_from,
          date_to: this.date_to,
        },
        options: {
          returnData: true,
        },
      });
    },
    async print() {
      let title = "Salary Record";

      let company_profile = this.$store.getters["company/company"];

      // Get HTML to print from element
      const prtHtml = document.getElementById("reportContent").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      const WinPrint = window.open(
        "",
        "PRINT",
        `height=${screen.height}, width=${screen.width}`
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                <div class="container container--fluid">
                    <div class="row row--dense">
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${
                                this.$store.state.host + company_profile.logo
                            }">
                        </div>
                        <div class="col-8 px-2 text-center">
                            <span class="font-weight-black">
                                ${company_profile.name}
                            </span>
                            <br>
                            <span>${company_profile.address}</span>
                            <br>
                            <span>
                                Email:${company_profile.email}, Contact:${ company_profile.phone}
                            </span>
                        </div>
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${this.$store.state.host + "linkup.png"}">
                        </div>
                        <div class="col-12 px-2 text-center">
                            <p class="font-weight-black custom_header">
                                ${title}
                            </p>
                        </div>
                        <div class="col col-12 px-2">
                            ${prtHtml}
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>`);

        WinPrint.document.head.innerHTML += `
            <style>
                .v-data-table__wrapper{
                    overflow: unset;
                }
                .record_table th{
                    text-align: center !important;
                }
                .custom_header{
                    background-color: #0000001f;
                    border-radius: 4px;
                    margin-bottom: 5px !important;
                }
                tr:hover{
                    background: unset !important;
                }
                .record_heading_tr:hover{
                    background: #4caf7773 !important;
                }
                @media print {
                    table.record_table { page-break-after:auto }
                    table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                    table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                    table.record_table thead { display:table-header-group }
                    table.record_table tfoot { display:table-footer-group }
                }
            </style>
        `;

      let rows = WinPrint.document.querySelectorAll(".record_table tr");
      rows.forEach((row) => {
        row.lastChild.remove();
      });

      WinPrint.focus();
      WinPrint.document.close();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
.record_table {
  margin-bottom: 5px;
}
.record_table table,
.record_table th,
.record_table td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  padding: 0 !important;
}
.record_table th,
.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}
.record_heading_tr {
  background: #4caf7773;
}

#no-background-hover::before {
  background-color: transparent !important;
}

>>> .v-btn--icon.v-size--default {
  height: unset;
  width: unset;
}

>>> .v-btn--icon.v-size--default .v-icon {
  height: unset;
  width: unset;
}
</style>